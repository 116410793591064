@import "../../../../variabels.scss";

.NextMembership {
  margin-bottom: 100px;

  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 40px;

    .left {
      width: 45vw;
      max-width: 550px;
      text-transform: uppercase;

      p {
        margin: 0;
        padding: 0;
      }

      .line1 {
        font-family: 'GothamBold';
        text-align: justify;
        font-size: 40px;
        letter-spacing: 0.5px;
        background: $next;
        padding-top: 16px;
        margin-bottom: 48px;
        line-height: 8px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line2 {
        margin-top: 36px;
        text-align: justify;
        font-size: 36px;
        line-height: 16px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line3 {
        text-align: justify;
        font-size: 32px;
        line-height: 16px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line4 {
        margin-top: 8px;
        text-align: justify;
        font-family: 'GothamBold';
        font-size: 48px;
        letter-spacing: 4px;
        line-height: 0;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line5 {
        text-align: justify;
        font-size: 28px;
        line-height: 16px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line6 {
        font-family: 'GothamMedium';
        text-align: justify;
        font-size: 36px;
        line-height: 16px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line7 {
        // font-family: 'GothamMedium';
        text-align: justify;
        font-size: 42px;
        letter-spacing: 1px;
        line-height: 16px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line8 {
        font-family: 'GothamMedium';
        text-align: justify;
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 24px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

      .line9 {
        font-family: 'GothamBold';
        text-align: justify;
        font-size: 106px;
        letter-spacing: 12px;
        margin-top: 16px;
        line-height: 8px;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
        }
      }

    }

    .space {
      width: 120px;
      min-width: 120px;
      height: 120px;
    }

    .right {
      width: 35vw;
      max-width: 450px;
      text-transform: uppercase;

      // margin-bottom: 15px;
      // margin-right: 20px;
      p {
        margin: 0;
        padding: 0;
      }

      .m-container {
        display: flex;
        align-items: center;
        gap: 20px;

        .v-line {
          width: 16px;
          height: 160px;
          background-color: $next;
        }

        .membership-fees {
          // flex-grow: 1;
          // background-color: aquamarine;

          .line1 {
            font-family: "GothamBold";
            text-align: justify;
            font-size: 28px;
            letter-spacing: 0.5px;
            padding-top: 8px;
            line-height: 28px;

            &:after {
              content: '';
              display: inline-block;
              width: 100%;
            }
          }

          .line2 {
            text-align: justify;
            font-size: 28px;
            line-height: 18px;

            &:after {
              content: '';
              display: inline-block;
              width: 100%;
            }
          }

          .line3 {
            font-family: 'GothamMedium';
            text-align: center;
            font-size: 16px;
            line-height: 16px;
          }

          .line4 {
            margin-top: 12px;
            text-align: justify;
            font-size: 20px;
            line-height: 18px;

            &:after {
              content: '';
              display: inline-block;
              width: 100%;
            }
          }
        }
      }

      .m-contact {
        font-family: GothamLight;
        text-transform: initial;
        margin-top: 20px;
        font-size: 16px;

        .email {
          font-style: normal;
          font-weight: bold;
          text-decoration: none;
          color: $next;
        }
      }

      .yearly-fees {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        margin-top: 20px;

        .fee {
          width: 100%;
          background-color: $next;
          height: 144px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;

          p {
            text-align: center;
          }

          .line2 {
            font-family: 'GothamBold';
            font-size: 36px;
            line-height: 36px;
          }
        }

        .fee.none {
          background-color: initial;
        }
      }
    }
  }

  @include medium-down {
    .body {
      flex-direction: column;
      align-items: flex-start;

      .left {
        width: 100%;
        max-width: none;
        margin-bottom: 20px; // Add margin-bottom for spacing

        .line1 {
          font-size: 24px;
          line-height: 10px;
        }

        .line2 {
          font-size: 24px;
          line-height: 14px;
        }

        .line3 {
          font-size: 20px;
          line-height: 12px;
        }

        .line4 {
          font-size: 30px;
          line-height: 14px;
        }

        .line5 {
          font-size: 18px;
          line-height: 14px;
        }

        .line6 {
          font-size: 22px;
          line-height: 14px;
        }

        .line7 {
          font-size: 18px;
          line-height: 12px;
        }

        .line8 {
          font-size: 18px;
          line-height: 12px;
        }

        .line9 {
          font-size: 64px;
          line-height: 12px;
        }
      }

      .right {
        width: 100%;
        max-width: none;
        margin-top: 20px; // Add margin-top for spacing
        // margin-right: 0;

        .m-container {
          display: flex;
          align-items: center;
          gap: 12px;

          .v-line {
            width: 16px;
            height: 144px;
            background-color: $next;
          }

          .membership-fees {
            .line1 {
              font-size: 20px;
            }

            .line2 {
              font-size: 24px;
              line-height: 12px;
            }

            .line4 {
              font-size: 16px;
              line-height: 12px;

              &:after {
                content: '';
                display: inline-block;
                width: 100%;
              }
            }
          }
        }

        .m-contact {
          font-size: 14px;
        }

        .yearly-fees {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 16px;
          margin-top: 20px;

          .fee {
            width: 100%;
            background-color: $next;
            height: 144px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;

            p {
              text-align: center;
            }

            .line2 {
              font-family: 'GothamBold';
              font-size: 28px;
              line-height: 36px;
            }
          }

          .fee.none {
            background-color: initial;
          }
        }
      }
    }
  }

  @include phone {
    .body {
      margin-top: 0;
    }
  }
}