@import "../../../../variabels.scss";

.NextAbout {
  color: #fff;
  padding-bottom: 100px;
  .container {
    .body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .left {
        flex: 1;
        min-width: 290px;

        p {
          font-family: GothamLight;
          font-size: 16px;
          line-height: 26px;
          color: #aaa;
          letter-spacing: 0.1px;
          text-align: justify;

          .strong {
            color: #ccc;
            font-family: GothamMedium;
          }
          a {
            color: #aad;
            text-decoration: none;
          }
        }
      }
      .space {
        width: 100px;
      }
      .right {
        flex: 1;
        min-width: 290px;

        .quote {
          position: relative;
          top: 144px;
          left: -25px;
          font-size: 300px;
          font-family: GothamMedium;
          line-height: 1px;
          // color: $next;
          opacity: 0.3;
          height: 10px;
        }
        p {
          color: #ccc;
          font-family: "Cormorant Garamond";
          font-size: 24px;
        }
      }
      .next {
        color: $next;
      }
    }
  }
}
