@import "../../../../variabels.scss";

.NextFounders {
  .founder-section {
    .common-text {
      font-size: 13px;
      line-height: 25px;
      font-family: GothamLight;
      font-weight: bold;

      a {
        font-size: 13px;
        line-height: 25px;
        font-family: GothamLight;
        font-weight: bold;
        text-decoration: none;
        color: #222;
      }

      .next {
        color: $next;
      }
    }

    .body {
      display: flex;
      justify-content: center; // Center align the items
      flex-wrap: wrap;
      padding-top: 20px;

      .AboutPeopleCard {
        display: flex;
        flex-direction: column;
        // background-color: aquamarine;
        // gap: 10px;
        flex: 1 1 calc(50% - 20px); // Allow a maximum of 2 cards in a row
        // margin: 10px;
        max-width: calc(50% - 20px); // Ensure the cards do not exceed 2 in a row
        // box-sizing: border-box; // Include padding and border in the element's total width and height
        position: relative;
        overflow: hidden;
        margin: 0;

        .img-area {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          // gap: 20px;
          // align-items: flex-end;
          overflow: hidden;

          .img-container {
            height: 406px;
            margin-bottom: 0;
            margin-right: 0;
            background-color: $next;
            padding-bottom: 0;

            img {
              height: 100%;
              padding-bottom: 0;
              margin-bottom: 0;

              @include phone {
                border: 4.5px solid $next;
              }
            }
          }

          .about {
            position: relative;
            padding: 20px;
            background-color: $next;
            display: flex;
            align-items: flex-end;

            .angle {
              position: absolute;
              width: 40px;
              height: 40px;
              background: $next;
              top: 50%;
              left: -20px;
              transform: translate(-50%, -50%);
              rotate: 45deg;

              @include phone {
                display: none;
              }
            }

            button {
              font-family: "GothamMedium";
              background: transparent;
              border: solid 1px;
              position: absolute;
              right: 20px;
              top: 20px;
              text-transform: uppercase;
              padding: 4px 8px;
              cursor: pointer;
              outline: 0;

              // &:hover {
              //   background: #000;
              //   color: #ccc;
              // }
            }

            .right {

              // background-color: lightcoral;
              .name {
                font-family: "Cormorant Garamond";
                font-weight: bold;
                font-size: 22px;
                line-height: 24px;
                margin-bottom: 5px;
              }

              .designations {
                font-family: GothamLight;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;

                p {
                  margin: 0;
                }
              }

              .email-container {
                margin-bottom: 20px;

                .email {
                  font-family: GothamLight;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 16px;
                  text-decoration: none;
                  color: #000;
                }

                .email.next {
                  // color: #000;
                }
              }
            }
          }

          @include phone {
            display: flex;
            flex-direction: column;
          }
        }

        .data {
          max-width: initial;
          margin-right: initial;
          margin-left: initial;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          background: linear-gradient(45deg, $blackishFrom, $blackishTo);
          transform: translateY(100%);
          transition: all 0.5s ease-in-out;
          color: #ccc;

          button {
            font-family: "GothamMedium";
            background: $next;
            border: solid 1px $next;
            position: absolute;
            right: 20px;
            top: 20px;
            text-transform: uppercase;
            padding: 4px 12px;
            cursor: pointer;
            outline: 0;
          }

          .testimonial {
            font-size: 12px;
            padding: 20px;

            @include phone {
              font-size: 11px;
              margin-top: 15px;
            }
          }
        }

        .data-show {
          transform: translateY(0);
        }

        @include phone {
          margin-top: 50px;
          flex: 1 1 100%; // One card per line on mobile view
          max-width: 100%;
        }
      }
    }
  }

  .co-founder-section {
    margin-top: 50px;

    .body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .side {
        width: 50%;

        @include phone {
          width: 100%;
        }

        .AboutPeopleCard {
          .img-area {
            .img-container {
              img {
                height: 258px;

                @include phone {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}