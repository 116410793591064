// Phone
$bp-small: 576px;
// tablet-sm
$bp-medium: 768px;
// tablet-lg
$bp-large: 1200px;
// Desktop
$bp-ex-large: 2048px;
// desktop-lg

@mixin small-up {
  @media (min-width: #{$bp-small}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$bp-medium}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$bp-large}) {
    @content;
  }
}

@mixin ex-large-up {
  @media (min-width: #{$bp-ex-large}) {
    @content;
  }
}
@mixin medium-down {
  @media (max-width: #{$bp-medium}) {
    @content;
  }
}

@mixin large-down {
  @media (max-width: #{$bp-large}) {
    @content;
  }
}
@mixin ex-large-down {
  @media (max-width: #{$bp-ex-large}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$bp-small}) {
    @content;
  }
}

@mixin tablet-sm {
  @media (min-width: #{$bp-small}) and (max-width: #{$bp-medium -1px}) {
    @content;
  }
}

@mixin tablet-lg {
  @media (min-width: #{$bp-medium}) and (max-width: #{$bp-large - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$bp-large}) and (max-width: #{$bp-ex-large - 1px}) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: #{$bp-ex-large}) {
    @content;
  }
}

$navbarHeight: 90px;

$blackishFrom: #000000;
$blackishTo: #2b251d;
$next: #ff7518;
