@import "../../../../variabels.scss";

.NextAdvisors {

  // margin-top: 50px;
  .people-pages {
    .body {
      .links-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: 80px;

        .links-container-bar {
          border-bottom: 4px solid #000000;
          position: absolute;
          width: 35px;
          bottom: 0px;
          left: 0;
          transition: left 1s;
        }

        .link {
          display: flex;
          align-items: center;
          margin-right: 4.5em;
          font-family: "GothamLight";
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          height: 100%;
          cursor: pointer;
          opacity: 0.4;
          transition: all 0.5s;

          &.active {
            font-family: GothamBold;
            opacity: 1;
          }
        }
      }

      .people {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: calc(100% / 4 - 244px); // Limit 4 cards per row.
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }

  .prince-philipp {
    .body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 50px;

      .left {
        margin-right: 40px;
      }

      .right {
        flex: 1;

        .text {
          font-family: "Cormorant Garamond";
          font-size: 48px;
          line-height: 49px;
          margin-bottom: 100px;
        }
      }
    }
  }

  @include large-down {
    .body {
      .links-container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 40px;
      }

      .links-container-bar {
        display: none;
      }

      .link {
        padding: 10px 0;
      }
    }
  }

  @include phone {
    // .people-pages {
    //   .body {
    //     .people {
    //       justify-content: center;
    //       // .AdvisorPeopleCard {
    //       //   height: auto;
    //       //   width: 90vw;
    //       //   margin-bottom: 80px;
    //       //   .img-container {
    //       //     height: auto;
    //       //   }
    //       // }
    //     }
    //   }
    // }

    .prince-philipp {
      .body {
        .left {
          margin-right: 0;
        }

        // .right {
        //   flex: 1;

        //   .text {
        //     font-family: "Cormorant Garamond";
        //     font-size: 48px;
        //     line-height: 49px;
        //     margin-bottom: 100px;
        //   }
        // }
      }
    }
  }
}